// Import Pinia and createApp
import { createApp } from "/node_modules/.vite/deps/vue.js?v=34d160ca";
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=34d160ca";
import ElementPlus from "/node_modules/.vite/deps/element-plus.js?v=34d160ca";
import "/node_modules/element-plus/dist/index.css";
import App from "/src/App.vue";
import router from "/src/router/index.js";
import setAuthorizationHeader from "/src/config/axios-setup.js"; // Import axios setup
import {
  formatDate,
  formatDateTable,
  formatDateTableWithTime,
  formatPrice,
  formatTextCapitalize,
  formatDateWithoutTime,
  formatDateTableReport,
  convertToSnakeCase,
  durationBetween2Dates,
  formatTextCapital,
  kebabToTitleCase,
  formatDateWithTime,
  getTagType,
  snakeCaseToTitleCase,
} from "/src/utils/formatter.js";
import { hargaNettoPoGreige } from "/src/utils/calculate.js";
import vue3lottie from "/node_modules/.vite/deps/vue3-lottie.js?v=34d160ca";
// Create Pinia instance
const pinia = createPinia();

// Create the app instance
const app = createApp(App);
// Execute the Axios setup to set the authorization header
setAuthorizationHeader();
// Use Pinia
app.use(pinia);

// Use router and ElementPlus
app.use(router);
app.use(ElementPlus);
app.use(vue3lottie);

app.config.globalProperties.$filters = {
  formatDate,
  formatDateTable,
  formatDateTableWithTime,
  formatPrice,
  formatTextCapitalize,
  hargaNettoPoGreige,
  formatDateWithoutTime,
  formatDateTableReport,
  convertToSnakeCase,
  durationBetween2Dates,
  formatTextCapital,
  kebabToTitleCase,
  formatDateWithTime,
  getTagType,
  snakeCaseToTitleCase,
};

// Mount the app
app.mount("#app");
